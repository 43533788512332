import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import { Container } from "react-bootstrap"

import Image from "./image"
import CustomNavbar from "./customNavBar"

const Header = ({ pageInfo }) => (
  <StaticQuery
    query={graphql`
          query {
            logo: file( relativePath: { eq: "logo.png" } ) {
              childImageSharp {
                 fluid(maxWidth: 160, srcSetBreakpoints: [35, 50, 90, 120, 160]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}

    render={data => (
      <header>
        <Container className="align-middle">
          <a href="/">
            <Image imgsrc={pageInfo.bannerTitle}/>
          </a>
        </Container>
        <Container>
          <Img div className="logo" fluid={data.logo.childImageSharp.fluid} alt="Alles Kids - Kindercoaching - Logo"/>
          <div className="section-heading">
            <h1 className="title">{pageInfo.pageTitle}</h1>
          </div>
          <CustomNavbar pageInfo={pageInfo}/>
        </Container>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
