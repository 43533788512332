import React from "react"
import { Nav, Navbar } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <Navbar collapseOnSelect expand="md" variant="light" id="site-navbar">
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
          <Nav activeKey={pageInfo && pageInfo.pageName}>
            <Nav.Item> <Nav.Link eventKey="index" href="/">Home</Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="wie-ben-ik" href="/wie-ben-ik"> Wie ben ik? </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="werkwijze" href="/werkwijze"> Werkwijze </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="specialisaties" href="/specialisaties"> Specialisaties </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="kindertekeningen" href="/kindertekeningen"> Analyse Kindertekeningen </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="tarieven" href="/tarieven"> Tarieven </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="recensies" href="/recensies"> Recensies </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="contact" href="/contact"> Contact </Nav.Link> </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
